exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-general-enquiry-jsx": () => import("./../../../src/pages/general-enquiry.jsx" /* webpackChunkName: "component---src-pages-general-enquiry-jsx" */),
  "component---src-pages-guide-detail-jsx": () => import("./../../../src/pages/guide-detail.jsx" /* webpackChunkName: "component---src-pages-guide-detail-jsx" */),
  "component---src-pages-guides-landing-jsx": () => import("./../../../src/pages/guides-landing.jsx" /* webpackChunkName: "component---src-pages-guides-landing-jsx" */),
  "component---src-pages-index-backup-js": () => import("./../../../src/pages/index-backup.js" /* webpackChunkName: "component---src-pages-index-backup-js" */),
  "component---src-pages-mortgage-calculator-jsx": () => import("./../../../src/pages/mortgage-calculator.jsx" /* webpackChunkName: "component---src-pages-mortgage-calculator-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-refer-friend-jsx": () => import("./../../../src/pages/refer-friend.jsx" /* webpackChunkName: "component---src-pages-refer-friend-jsx" */),
  "component---src-pages-service-landing-jsx": () => import("./../../../src/pages/service-landing.jsx" /* webpackChunkName: "component---src-pages-service-landing-jsx" */),
  "component---src-pages-step-1-jsx": () => import("./../../../src/pages/step-1.jsx" /* webpackChunkName: "component---src-pages-step-1-jsx" */),
  "component---src-pages-step-2-jsx": () => import("./../../../src/pages/step-2.jsx" /* webpackChunkName: "component---src-pages-step-2-jsx" */),
  "component---src-pages-step-3-jsx": () => import("./../../../src/pages/step-3.jsx" /* webpackChunkName: "component---src-pages-step-3-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-calculator-template-jsx": () => import("./../../../src/templates/calculator-template.jsx" /* webpackChunkName: "component---src-templates-calculator-template-jsx" */),
  "component---src-templates-contact-template-jsx": () => import("./../../../src/templates/contact-template.jsx" /* webpackChunkName: "component---src-templates-contact-template-jsx" */),
  "component---src-templates-default-template-jsx": () => import("./../../../src/templates/default-template.jsx" /* webpackChunkName: "component---src-templates-default-template-jsx" */),
  "component---src-templates-get-started-template-jsx": () => import("./../../../src/templates/get-started-template.jsx" /* webpackChunkName: "component---src-templates-get-started-template-jsx" */),
  "component---src-templates-guide-details-template-jsx": () => import("./../../../src/templates/guide-details-template.jsx" /* webpackChunkName: "component---src-templates-guide-details-template-jsx" */),
  "component---src-templates-guide-landing-template-jsx": () => import("./../../../src/templates/guide-landing-template.jsx" /* webpackChunkName: "component---src-templates-guide-landing-template-jsx" */),
  "component---src-templates-home-template-jsx": () => import("./../../../src/templates/home-template.jsx" /* webpackChunkName: "component---src-templates-home-template-jsx" */),
  "component---src-templates-static-template-jsx": () => import("./../../../src/templates/static-template.jsx" /* webpackChunkName: "component---src-templates-static-template-jsx" */),
  "component---src-templates-team-template-jsx": () => import("./../../../src/templates/team-template.jsx" /* webpackChunkName: "component---src-templates-team-template-jsx" */)
}

